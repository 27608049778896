div.ngxp__container {
  background: #777;
  color: white;
  border: 0;
  max-width: 250px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 14px 0 rgba(0, 0, 0, 0.12);
  z-index: 999;
}

div.ngxp__container > .ngxp__arrow:before {
  background-color: #777;
}
